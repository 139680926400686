<template>
  <base-section :title="$t('company_settings.details_page.financial_section.title')" :key="key">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.input_label.iban')"
          ref="iban"
          prop="iban"
          v-model="form.iban"
          name="iban"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.input_label.bic')"
          type="text"
          ref="bic"
          prop="bic"
          v-model="form.bic"
          name="bic"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.input_label.po_number')"
          ref="poNumber"
          prop="poNumber"
          v-model="form.poNumber"
          name="poNumber"
          :info="$t('corporate_accounts_management.add_page.company_details.po_number_explanation')"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <base-select
          v-if="showInvoicingSendingMethod"
          clearable
          ref="invoicingSendingMethod"
          prop="invoicingSendingMethod"
          :label="$t('company_settings.financial.invoice_sending_method.label')"
          v-model="form.invoicingSendingMethod"
          :items="invoicingSendingMethods"
          :info="$t('company_settings.financial.invoice_sending_method.info_text')"
        />
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          v-if="form.invoicePaymentMethod"
          :label="$t('company_settings.financial.invoice_payment_method.label')"
          :value="invoicePaymentMethodValue"
          ref="invoicePaymentMethod"
          prop="invoicePaymentMethod"
          name="invoicePaymentMethod"
          readonly
        />
      </el-col>
    </el-row>
  </base-section>
</template>

<script>
export default {
  name: 'CompanyFinancialSection',
  data () {
    return {
      key: '',
      invoicingSendingMethods: [{ value: 'EMAIL' }, { value: 'PEPPOL' }]
    }
  },
  props: {
    value: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: true
    },
    partnerData: {
      type: Object,
      default: () => ({})
    },
    ospId: {
      type: String,
      default: ''
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    isDefaultCA () {
      const source = this.ospId.split('.')
      return source[0] === source[2]
    },
    showInvoicingSendingMethod () {
      return (
        (this.partnerData.invoiceMsp && this.isDefaultCA) ||
        this.partnerData.invoiceCorporateAccounts
      )
    },
    invoicePaymentMethodValue () {
      if (this.form.invoicePaymentMethod) {
        const locoKey = `company_settings.financial.invoice_payment_method.${this.form.invoicePaymentMethod.toLowerCase()}`
        return this.$te(locoKey) ? this.$t(locoKey) : this.form.invoicePaymentMethod
      }
      return ''
    }
  },
  watch: {
    'form.invoicingSendingMethod' () {
      this.form.invoicingSendingMethod =
      this.form.invoicingSendingMethod === ''
        ? null
        : this.form.invoicingSendingMethod
    }
  }
}
</script>

<style scoped></style>
