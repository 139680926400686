<template>
  <base-section
    :title="$t('company_settings.details_page.legal_section.title')"
    :key="key"
  >
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.label.legal_number')"
          type="text"
          ref="legalName"
          prop="legalName"
          v-model="form.legalName"
          name="legalName"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <base-input
          :label="$t('general.label.company_number')"
          ref="companyNumber"
          prop="companyNumber"
          v-model="form.companyNumber"
          name="companyNumber"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <base-checkbox
          :label="
            edit
              ? $t('general.label.my_company_is_vat')
              : $t('general.label.corporate_is_vat')
          "
          v-model="form.vatLiable"
          ref="isVatLiable"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.label.vat_number')"
          ref="vatNumber"
          prop="vatNumber"
          v-model="form.vatNumber"
          name="vatNumber"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
  </base-section>
</template>
<script>
export default {
  name: 'CompanyLegalSection',
  data () {
    return {
      key: '',
      error: ''
    }
  },
  props: {
    value: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style scoped></style>
