<template>
  <base-section
    :title="$t('company_settings.details_page.address_section.title')"
    :key="key"
  >
    <el-row :gutter="24">
      <el-col :span="16">
        <base-input
          :label="$t('general.label.street_name')"
          ref="streetName"
          prop="streetName"
          v-model="form.street"
          :validations="$v.form.street"
          name="streetName"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="8">
        <base-input
          :label="$t('general.label.street_number')"
          type="text"
          ref="streetNumber"
          prop="streetNumber"
          v-model="form.streetNumber"
          :validations="$v.form.streetNumber"
          name="streetNumber"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="8">
        <base-input
          :label="$t('general.label.zip_code')"
          ref="zipCode"
          prop="zipCode"
          v-model="form.zipCode"
          name="zipCode"
          :validations="$v.form.zipCode"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="16">
        <base-input
          :label="$t('general.label.city')"
          ref="city"
          prop="city"
          v-model="form.city"
          :validations="$v.form.city"
          name="city"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-select
          :label="$t('general.label.country')"
          v-model="form.country"
          :validations="$v.form.country"
          :items="countries"
          :default-first-option="true"
          filterable
        >
        </base-select>
<!--        <base-input-->
<!--          :label="$t('general.label.country')"-->
<!--          ref="city"-->
<!--          prop="city"-->
<!--          v-model="form.country"-->
<!--          :validations="$v.form.country"-->
<!--          name="city"-->
<!--        />-->
      </el-col>
    </el-row>
  </base-section>
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { countries } from '@/app/services/CountriesService/CountriesService'

export default {
  name: 'CompanyAddressSection',
  data () {
    return {
      key: '',
      countries: countries().getList()
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  validations: {
    form: {
      street: {
        required: requiredIf(function (nestedModel) {
          return !!nestedModel.streetNumber || !!nestedModel.city || !!nestedModel.zipCode
        })
      },
      streetNumber: {
        required: requiredIf(function (nestedModel) {
          return !!nestedModel.street || !!nestedModel.city || !!nestedModel.zipCode
        })
      },
      city: {
        required: requiredIf(function (nestedModel) {
          return !!nestedModel.street || !!nestedModel.streetNumber || !!nestedModel.zipCode
        })
      },
      zipCode: {
        required: requiredIf(function (nestedModel) {
          return !!nestedModel.street || !!nestedModel.streetNumber || !!nestedModel.city
        })
      }
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  mounted () {
    this.form.country = this.form.country || 'BE'
    this.key = this.$key()
  }
}
</script>

<style scoped>

</style>
